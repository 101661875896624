<template>
  <div>
    <b-col>
      <h2 class="sprint_slider__title">Большие цели</h2>
    </b-col>

    <div class="sprint_global_goals__row">
      <div v-if="allGlobalGoals">
        <b-col>
          <div
            class="swiper-slide"
            v-b-modal.global_goal
            @click="setActiveGlobalGoal()"
          >
            <div class="sprint_card">
              <div class="sprint_card__content">
                <p class="sprint_card__add">+</p>
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          v-b-modal.global_goal
          @click="setActiveGlobalGoal(globalGoal)"
          v-for="(globalGoal, index) in allGlobalGoals"
          :key="index"
        >
          <div class="sprint_card">
            <div class="sprint_card__img_filter" v-if="globalGoal.img"></div>

            <img
              v-if="globalGoal.img"
              class="sprint_card__img"
              :src="
                $store.state.storageUrl +
                '/app/sprint/global_goals/' +
                globalGoal.img
              "
            />

            <b-row class="sprint_card__content" align-h="end">
              <b-col cols="12">
                <h3 class="sprint_card__title">{{ globalGoal.title }}</h3>
                <p class="sprint_card__description">
                  {{ globalGoal.description }}
                </p>
              </b-col>

              <b-col v-if="globalGoal.life_sphere" cols="auto" align-self="end">
                <div class="sprint_card__tag">
                  <p class="sprint_card__tag_text">
                    {{ globalGoal.life_sphere.title }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </div>

      <!-- Global Goal MODAL -->
      <b-modal
        id="global_goal"
        size="lg"
        hide-footer
        header-bg-variant="dark"
        body-bg-variant="dark"
        centered
      >
        <b-row align-h="center" v-if="globalGoalEditor.img">
          <div class="global_goal__img_wrapper">
            <img
              class="global_goal__img"
              :src="
                $store.state.storageUrl +
                '/app/sprint/global_goals/' +
                globalGoalEditor.img
              "
            />
          </div>
        </b-row>

        <b-row v-if="globalGoalEditor.id" class="goals_delete">
          <b-col cols="12">
            <b-button
              :disabled="loading"
              type="button"
              @click="deleteGlobalGoal(globalGoalEditor.id)"
              variant="outline-danger"
              size="sm"
              >удалить</b-button
            >
          </b-col>
        </b-row>

        <!-- Info -->
        <b-row>
          <b-col cols="12">
            <div class="global_goal__new_img">
              <label>Картинка</label>
              <b-form-file
                v-model="globalGoalEditor.newImg"
                :state="Boolean(globalGoalEditor.newImg)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </div>

            <b-form-checkbox
              v-if="globalGoalEditor.done != null"
              id="globalGoalDone"
              v-model="globalGoalEditor.done"
              name="globalGoalDone"
              :value="1"
              :unchecked-value="0"
              switch
              size="lg"
              class="global_goal__done"
            >
              Достиг
            </b-form-checkbox>

            <label>Сфера жизни</label>
            <b-form-select
              class="gloval_goal__status"
              v-model="globalGoalEditor.life_sphere.id"
              :options="spheres"
              value-field="id"
              text-field="title"
            ></b-form-select>

            <label>Название</label>
            <b-form-input
              v-model="globalGoalEditor.title"
              class="global_goal__title"
              placeholder="Название"
            ></b-form-input>

            <label>Описание</label>
            <b-form-input
              v-model="globalGoalEditor.description"
              class="global_goal__description"
              placeholder="Описание"
            ></b-form-input>
          </b-col>
        </b-row>

        <!-- Save button -->
        <b-row v-if="globalGoalEditor.id">
          <b-button
            :disabled="loading"
            @click="saveGlobalGoal"
            type="button"
            block
            >Сохранить</b-button
          >
        </b-row>

        <b-row v-else>
          <b-button
            :disabled="loading"
            @click="addGlobalGoal()"
            type="button"
            block
            >Создать</b-button
          >
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import lodash from "lodash";
import axios from "axios";

export default {
  name: "GlobalGoal",
  props: {
    statuses: Array,
    spheres: Array,
  },
  data() {
    return {
      globalGoalEditor: {
        id: null,
        title: null,
        description: null,
        img: null,
        newImg: null,
        done: null,
        life_sphere: {
          id: null,
          title: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["loading", "allGlobalGoals"]),
  },
  methods: {
    ...mapActions(["getGlobalGoals"]),
    formatDate(date) {
      moment.locale("ru");
      return moment(date).format("LL");
    },
    weekDayFormat(date) {
      moment.locale("ru");
      return moment(date).format("ddd LL");
    },

    setActiveGlobalGoal(globalGoal) {
      if (globalGoal) {
        this.globalGoalEditor = lodash.cloneDeep(globalGoal);
      } else {
        this.globalGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          done: null,
          life_sphere: {
            id: 1,
            title: null,
          },
        };
      }
    },
    async addGlobalGoal() {
      let formData = new FormData();
      formData.append("title", this.globalGoalEditor.title);
      formData.append("description", this.globalGoalEditor.description);
      formData.append("done", this.globalGoalEditor.done);
      formData.append("life_sphere_id", this.globalGoalEditor.life_sphere.id);
      formData.append("new_img", this.globalGoalEditor.newImg);

      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post("global_goals", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.$bvModal.hide("global_goal");
        this.globalGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          done: null,
          life_sphere: {
            id: 1,
            title: null,
          },
        };

        this.$bvToast.toast("Глобальная цель успешно добавлена");

        await this.getGlobalGoals();

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
    async saveGlobalGoal() {
      let formData = new FormData();
      formData.append("title", this.globalGoalEditor.title);
      formData.append("description", this.globalGoalEditor.description);
      formData.append("done", this.globalGoalEditor.done);
      formData.append("life_sphere_id", this.globalGoalEditor.life_sphere.id);
      formData.append("new_img", this.globalGoalEditor.newImg);

      try {
        this.$store.commit("SET_LOADING", true);
        await axios.post(`global_goals/${this.globalGoalEditor.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await this.getGlobalGoals();

        this.$bvModal.hide("global_goal");
        this.globalGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          done: null,
          life_sphere: {
            id: 1,
            title: null,
          },
        };

        this.$bvToast.toast("Сохранено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    async deleteGlobalGoal(globalGoalId) {
      try {
        this.$store.commit("SET_LOADING", true);
        await axios.delete(`global_goals/${globalGoalId}`);

        await this.getGlobalGoals();

        this.$bvModal.hide("global_goal");
        this.globalGoalEditor = {
          id: null,
          title: null,
          description: null,
          img: null,
          newImg: null,
          done: null,
          life_sphere: {
            id: 1,
            title: null,
          },
        };

        this.$bvToast.toast("Глобальная цель удалена");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$bvToast.toast(`${e}`);
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.sprint_goals_row {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
.sprint_global_goals__row {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
</style>
